.contact-compass-glass {
  h3 {
    margin: $spacing 0;
    color: $deepcerulean;
    @include font-size(30px);
    font-weight: $font-weight-regular;
  }

  address {
    padding: 25px 0;
    font-style: normal;

    h4 {
      margin-bottom: 1rem;
    }

    span,
    span a,
    address {
      @include font-size(14px);
      font-weight: 300;
      line-height: $line-height-default;
      text-decoration: none;
      padding: 0.1rem 0;
      color: $deepcerulean;

      @include breakpoint($laptop) {
        @include font-size(16px);
      }
    }

    address {
      padding: $spacing 0;
      max-width: 100%;
      @include font-size(20px);
      font-style: italic;

      @include breakpoint($laptop) {
        padding: $spacing 0 $spacing 0;
        max-width: 300px;
        min-height: 120px;
      }
    }
  }
  iframe {
    width: 100%;
    height: auto;
    min-height: 250px;
    border: none;
    margin-top: $spacing;
    @include breakpoint($laptop) {
      width: 300px;
      height: 250px;
    }
  }

  .map {
    min-width: 300px;
  }
}
