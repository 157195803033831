.footer-section {
  position: relative;
  background: $congressblue;
  z-index: 100;
  flex-shrink: 0;
  flex: 0 1 auto;
}

.footer {
  color: #80adc3;
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  padding: $spacing 0;
  text-align: center;

  @include breakpoint($laptop) {
    padding: 0 $spacing * 4;
    text-align: left;
  }

  p {
    color: #d3dfe9;
    @include font-size(14px);
    max-width: 60vw;
    margin: $spacing auto;
    line-height: $line-height-default;

    @include breakpoint($laptop) {
      max-width: 100%;
      margin: 0;
      @include font-size(18px);
    }

    a {
      color: #d3dfe9;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .icon-group {
    min-width: 145px;
    margin: $spacing 0;

    @include breakpoint($laptop) {
      margin: 0;
    }
  }
}
