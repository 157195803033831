.button {
  position: relative;
  background: $pigeonpost;
  color: #fff;
  @include font-size(22px);
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  min-width: 160px;
  min-height: 50px;
  line-height: $line-height-default;
  @include transition(all 0.1s linear);
  border: 1px solid transparent;
  border-radius: 0;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0.75rem 1rem;

  @include breakpoint($laptop) {
    margin: 0;
  }

  &:hover {
    background: darken($pigeonpost, 20%);
  }

  &:active,
  &:focus {
    top: 1px;
    opacity: 0.95;
  }

  &.default {
    background: $pigeonpost;
    border-color: $pigeonpost;
  }

  &.primary {
    background: $wedgewood;
    border-color: $wedgewood;
    color: #fff;

    &:hover {
      background: darken($wedgewood, 10%);
    }
  }
}
