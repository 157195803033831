*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  @include font-size(16px);
  font-family: $font-primary;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $blacksqueeze
    url('../../images/Compass-Glass-Background-Gradient.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.container {
  width: 100%;
  max-width: $container;
  margin: 0 auto;
}

.section {
  width: $section;
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  @include breakpoint($laptop) {
    background: inherit;
  }
}

address {
  padding: 25px 0;
  font-style: normal;

  h4 {
    margin-bottom: 1rem;
  }

  span,
  span a,
  address {
    display: flex;
    align-items: center;
    @include font-size(18px);
    font-weight: 400;
    line-height: $line-height-default;
    text-decoration: none;
    padding: 0.1rem 0;
    color: $deepcerulean;
    letter-spacing: -0.25px;

    @include breakpoint($laptop) {
      @include font-size(20px);
    }

    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  address {
    padding: $spacing 0;
    max-width: 100%;
    @include font-size(20px);
    font-style: italic;
    font-weight: 300;

    @include breakpoint($laptop) {
      padding: $spacing 0 $spacing 0;
      max-width: 300px;
      min-height: 120px;
    }
  }
}

iframe {
  width: 100%;
  height: auto;
  min-height: 350px;
  border: none;
  box-shadow: 0px 2px 40px 0px #eaeaea;
  @include breakpoint($laptop) {
    width: 400px;
    height: 350px;
  }
}

.icon-group {
  display: flex;
  justify-content: space-between;

  svg {
    fill: #6d9bbf;
    width: 40px;
    height: 40px;
  }
}

// Background Video

.video-wrapper {
  position: relative;
}

.video-wrapper video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url('../../images/Compass-Glass-Background.jpg') no-repeat;
  background-size: cover;
}

// Forms

form {
  .button {
    cursor: pointer;
    &.primary {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.form {
  margin: $spacing * 2 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: $spacing 0 $spacing * 1.5 0;

  h4 {
    @include font-size(20px);
    margin: 0 0 $spacing 0;
  }

  .form-error {
    margin: 0;
    padding: $spacing 0 0 0;
    @include font-size(16px);
    font-weight: $font-weight-regular;
    color: $fuzzy;
  }

  label {
    color: $valhalla;
    margin-bottom: $spacing;
    text-align: left;
    font-weight: $font-weight-semi-bold;
  }

  input,
  textarea {
    border: 2px solid $blacksqueeze;
    padding: $spacing * 1 $spacing;
    width: 100%;
    box-shadow: $box-shadow-forms;
    border-radius: 2px;
    background: lighten($aliceblue, 0.5%);

    &::placeholder {
      color: $congressblue;
    }

    &:active,
    &:focus {
      border: 2px solid darken($blacksqueeze, 10%);
      outline: none;
      background: lighten($aliceblue, 5%);
    }
  }

  textarea {
    min-height: 200px;
    line-height: $line-height-1-8;
  }
}

.alert {
  margin: 0;
  padding: $spacing 0;
  line-height: $line-height-1-8;
  @include font-size(16px);
  font-weight: $font-weight-regular;
  color: $fuzzy;
}

// Tables

.table-container {
  overflow: hidden;
  overflow-x: scroll;

  @include breakpoint($laptop) {
    overflow: visible;
  }
}

.table {
  width: 100%;
  margin-bottom: $spacing;
  color: $valhalla;
  vertical-align: middle;
  border-color: transparent;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: $box-shadow-default;
  border-collapse: collapse;

  > tbody {
    vertical-align: inherit;
  }

  > thead {
    vertical-align: bottom;
    background: darken($pigeonpost, 20%);
    box-shadow: 0 1px 2px 0 $linkwater;
  }

  td,
  th {
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
    @include font-size(20px);
    overflow: hidden;
    padding: $spacing * 1.5 $spacing * 2;
    word-break: normal;
    font-weight: $font-weight-light;
  }

  th {
    @include font-size(24px);
    font-weight: $font-weight-bold;
    box-shadow: 1px 0 2px 0 #fff;
    color: #fff;
  }

  &.striped {
    td {
      // border-bottom: 1px solid lighten($pigeonpost, 10%);
      box-shadow: 0 1px 2px 0 lighten($pigeonpost, 15%);
    }
    tr {
      &:nth-child(odd) {
        td {
          background: lighten($linkwater, 10%);
        }
      }
      td {
        &.cell-origin {
          color: $glacier;
          position: relative;
          background: #f6f8f9;
          border-bottom: 1px solid #e3ecf3;
          font-weight: 500;
          box-shadow: 1px 0 1px #dfe6eb;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

small {
  line-height: $line-height-1-8;
  color: $wedgewood;
  padding: $spacing $spacing * 1.2;
  margin: $spacing 0 0 0;
  max-width: 90%;
  display: inline-block;
}

// Certifications

.certifications-logos {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 40px 20px;
  border-bottom: 1px solid $blacksqueeze;

  @include breakpoint($laptop) {
    flex-wrap: nowrap;
    padding: 40px 60px;
  }

  img {
    max-width: 90%;
  }
}

// Animations

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  &.slower {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }
  &.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

// Loading indicator

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loading {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border: 0.35rem solid rgba(255, 255, 255, 0.2);
  border-top-color: $valhalla;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

// Non payment

// #root *,
// body,
// html {
//   visibility: hidden !important;
//   display: none !important;
// }
