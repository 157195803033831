.header-section {
  background: transparent;
  z-index: 1;
  position: relative;
  min-width: 350px;
  cursor: pointer;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @include transition(all 0.15s linear);
  padding: 0 $spacing * 4 $spacing * 2 $spacing * 5;
  max-height: 250px;

  .flex-column {
    flex-direction: row;

    @include breakpoint($desktop) {
      flex-direction: column;
    }
  }

  .nav-container {
    flex-direction: column;
    @include breakpoint($desktop) {
      flex-direction: row;
    }
  }

  nav {
    @include font-size(25px);
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 $spacing 0;
    align-items: center;

    @include breakpoint($laptop) {
      @include font-size(25px);
      margin: 0 0 $spacing 0;
    }

    @include breakpoint($desktop) {
      justify-content: flex-end;
      margin: 0 0 $spacing 0;
      align-items: flex-end;
      width: 60vw;
    }

    a {
      position: relative;
      padding: 10px 0 10px 15px;
      margin: 5px 25px;
      outline: none;
      text-decoration: none;
      color: $wedgewood;
      @include transition(all 0.15s);
      letter-spacing: -0.5px;
      z-index: 1;
      font-weight: 500;

      &:hover {
        color: $valhalla;
      }

      &.active {
        color: $valhalla;
        font-weight: 600;
      }
    }
  }
}

.logo {
  position: relative;
  top: $spacing * 3;
  svg {
    max-width: 80%;
    @include breakpoint($desktop) {
      max-width: 80%;
    }
  }
}

.layout-aside {
  .header-section {
    min-width: 400px;
  }

  .header {
    flex-direction: column;
    position: sticky;
    top: 0;

    .nav-container {
      flex-direction: column;
    }

    nav {
      flex-direction: column;
      width: 100%;
      margin-top: $spacing * 6;
      align-items: flex-start;
    }
  }
}

// Headroom

.headroom {
  @include transition(all 0.25s);
}

.compass-glass-south-africa {
  .headroom--unfixed {
    @include transition(all 0.25s);
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}
