.home-section {
  position: relative;

  .home {
    margin: 0;
    @include breakpoint($laptop) {
      margin-top: -4rem;
    }
  }
}

.hero {
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-between;

  @include breakpoint($laptop) {
    max-width: 50%;
    padding: 20px 0 100px $spacing * 4;
    background: transparent;
  }

  @include breakpoint($desktop) {
    padding: 100px $spacing * 4;
    max-width: 80%;
  }

  @include breakpoint(1560px) {
    padding: 100px 0;
  }

  p {
    @include font-size(20px);
    color: darken($congressblue, 20%);
    line-height: $line-height-2;

    @include breakpoint($laptop) {
      @include font-size(25px);
    }
  }

  .hero-text {
    padding-left: $spacing * 2;
    text-align: center;

    @include breakpoint($laptop) {
      text-align: left;
    }

    h1 {
      @include font-size(30px);

      @include breakpoint($laptop) {
        @include font-size(55px);
      }
    }
  }
}
