@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

a {
  color: $deepcerulean;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: -2.5px;
}

h1 {
  @include font-size(30px);
  font-weight: 600;
  color: $congressblue;

  @include breakpoint($laptop) {
    @include font-size(40px);
  }
}

h2 {
  @include font-size(30px);
  font-weight: 600;
  color: $nepal;
  letter-spacing: -2.5px;

  @include breakpoint($laptop) {
    @include font-size(40px);
  }
}

h3 {
  @include font-size(20px);
  font-weight: 600;
  color: $deepcerulean;
  letter-spacing: -2px;

  @include breakpoint($laptop) {
    @include font-size(35px);
  }
}

h4 {
  @include font-size(25px);
  font-weight: bold;
  color: $deepcerulean;
  letter-spacing: -1px;
}

h5 {
  @include font-size(22px);
  font-weight: bold;
  color: $deepcerulean;
  letter-spacing: -1px;
}

p {
  @include font-size(20px);
  font-weight: 300;
  color: $mineshaft;
}
