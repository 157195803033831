.basic-layout {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;

  .video-wrapper {
    display: none;
  }

  &.compass-glass-south-africa {
    .video-wrapper {
      display: block;
    }

    animation-fill-mode: none;
    animation-duration: 0ms;

    .card {
      p {
        @include breakpoint($laptop) {
          max-width: 30vw;
        }
      }
    }
  }
}

// Default Page

.page {
  padding: $spacing;
  background: transparent;

  @include breakpoint($laptop) {
    padding: $spacing * 4 $spacing * 2;
  }

  .hero {
    padding-top: $spacing * 3;
  }

  .card-padding-default {
    padding: 30px 32px;
    @include breakpoint($laptop) {
      padding: 60px 80px;
    }
  }

  .card-padding-narrow {
    padding: 40px;
    @include breakpoint($laptop) {
      padding: 60px 60px;
    }
  }

  h1 {
    padding-bottom: $spacing;
    line-height: $line-height-default;
    @include breakpoint($laptop) {
      padding-bottom: $spacing * 2;
    }
  }
}

.layout-columns {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  top: 0;
}

.layout-aside {
  flex-direction: row;
}

.layout-landing {
  .header-section {
    background: #fff;
    box-shadow: $box-shadow-header;
    .header {
      padding: $spacing 0;
      margin: 0;
      .nav-container {
        padding: 0;
        display: flex;
        align-items: center;
      }
      nav {
        width: 100%;
        margin: 0;
        a {
          @include font-size(14px);
          margin: 5px;
          @include breakpoint($laptop) {
            @include font-size(22px);
            margin: 5px 20px;
          }
        }
      }
    }
    .logo {
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      max-height: 47px;
      text-align: center;
      a {
        max-height: 47px;
        svg {
          max-width: 60%;

          @include breakpoint($laptop) {
            max-width: 55%;
          }
        }
      }
    }
  }
  .hero {
    margin: 0 auto;
    max-width: 100%;
    .logo {
      top: -2rem;
      @include breakpoint($laptop) {
        top: 1rem;
      }
      svg {
        max-width: 50%;
        display: block;
        margin: 0 auto;

        @include breakpoint($laptop) {
          max-width: 80%;
        }
      }
    }
    .hero-text {
      padding: 2rem 2rem 0 2rem;

      @include breakpoint($laptop) {
        padding-left: $spacing * 3;
      }
    }
  }
}

// Cards

.cards-container {
  position: relative;
}

.card {
  position: relative;
  text-align: center;

  @include breakpoint($laptop) {
    text-align: left;
  }

  &.secondary {
    background: $card-background-secondary;
    background: inherit;
  }

  img {
    box-shadow: none;
    max-width: 400px;

    @include breakpoint($laptop) {
      max-width: 100%;
    }

    &.map {
      box-shadow: $box-shadow-map;
    }
  }

  h2,
  h3 {
    padding-top: $spacing * 2;
    padding-bottom: $spacing;

    @include breakpoint($laptop) {
      padding-top: 0;
    }
  }

  p {
    @include font-size(16px);
    line-height: $line-height-2;
    color: $wedgewood;
    max-width: 100%;
    padding: 0.5rem 0;
    margin: 0;
    @include breakpoint($laptop) {
      @include font-size(20px);
    }
  }

  .button {
    margin-top: $spacing;
  }

  &.card-with-map {
    background: $card-background-primary
      url('../../../assets/images/Compass-Glass-Map-Background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }
}

.card-padding-default {
  padding: 40px 20px;
  @include breakpoint($laptop) {
    padding: 80px 60px;
  }
}

.card-cta {
  background: #f6fafb
    url('../../../assets/images/Compass-Glass-Background-transparent.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: $spacing * 3 $spacing $spacing * 4 $spacing;
  border-top: 1px solid $linkwater;

  @include breakpoint($laptop) {
    padding: $spacing * 5 $spacing * 10;
  }
}
