.not-found {
  height: 90vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 100px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    height: 120px;
    line-height: 120px;

    h1 {
      font-size: 100px;
    }
    div {
      font-size: 65px;
      margin-left: 24px;
      color: yellowgreen;
    }
  }

  p {
    width: 400px;
  }
}
