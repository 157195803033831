// Utilities

// Layout

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
  @include breakpoint($laptop) {
    flex-direction: row;
  }
}

// Borders

.border-top {
  border-top: 1px solid;
}

.border-radius-none {
  border-radius: 0;
}

// Spacing

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-1 {
  padding-top: 1rem !important;
}

.padding-top-3 {
  padding-top: 3rem !important;
}

.padding-bottom-1 {
  padding-bottom: 1rem !important;
}

.padding-bottom-3 {
  padding-bottom: 3rem !important;
}

.padding-left-2 {
  padding-left: 2rem !important;
}

.border-radius-5 {
  border-radius: 5;
}

.border-linkwater {
  border-color: $linkwater;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: center;
  @include breakpoint($laptop) {
    justify-content: flex-end;
  }
}

.align-items-center {
  align-items: center;
}

.position-fixed {
  position: relative;

  @include breakpoint($desktop) {
    position: fixed;
  }
}

.position-sticky {
  position: sticky;
  top: 45px;
}

.vh-100 {
  height: 100vh;
}

.height-250 {
  height: 250px;
  min-height: 250px;
}

.height-350 {
  height: 350px;
  min-height: 350px;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.text-decoration-none {
  text-decoration: none;
}

// Links

.link-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 $spacing * 2 0;

  @include breakpoint($laptop) {
    justify-content: flex-start;
    margin: 0;
  }

  svg {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: 0 $spacing / 2 0 0;
  }
}

// Appearance

.glass {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 50px rgba(227, 227, 227, 0.75);

  @include breakpoint($laptop) {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.frosted-glass {
  backdrop-filter: blur(5px);
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
