.faq {
  .faq-container {
    display: block;
  }

  .card {
    h4 {
      margin-bottom: $spacing;
    }

    p {
      @include font-size(16px);
      @include breakpoint($laptop) {
        @include font-size(18px);
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

/* Accordion styles */

.tabs {
  border-radius: 0;
  overflow: hidden;
  box-shadow: $box-shadow-default;
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  margin-bottom: $spacing;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: darken($pigeonpost, 20%);
    @include font-size(20px);
    font-weight: $font-weight-bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: lighten($congressblue, 1%);
    }
    &::after {
      content: '\276F';
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 $spacing * 2;
    background: $congressblue;
    background: white;
    transition: all 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $valhalla;
    cursor: pointer;
    &:hover {
      background: $valhalla;
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: $congressblue;
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: $spacing $spacing * 2;
  }
}
