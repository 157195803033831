.compass-glass-technical-information {
  .card {
    h2 {
      padding: $spacing 0;
    }
    h3 {
      padding: $spacing 0 $spacing * 2 0;
    }

    .small {
      p {
        line-height: $line-height-1-8;
        color: $wedgewood;
        padding: $spacing $spacing * 1.2;
        margin: $spacing 0 0 0;
        max-width: 90%;
        display: inline-block;
        font-size: 80%;
        font-weight: 500;
      }
    }
  }
}
